.main {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;

    .labelDiv {
        width: 100px;
        padding: 10px;
        color: #fff;
        display: block;
        font-size: 14px;
        position: absolute;
        bottom: 0;
        z-index: -99;
        background-color: rgba(20, 143, 211, 0.68);
        // box-shadow: 0 0 12px rgba(0, 128, 255, 0.75);
        border: 1px solid rgba(127, 177, 255, 0.75);
    }

    .displayPanel {
        width: 200px;
        height: 200px;
        left: 200px;
        top: 100px;
        color: #fff;
        display: block;
        font-size: 14px;
        position: absolute;
        z-index: 10;

        .panelContent {
            padding: 20px;
            text-align: left;
            color: black;

            .title {
                margin-top: 5px;

                text-align: left;

            }

            .moduleName {

                margin-top: 5px;
                margin-left: 10px;
            }

            .moduleFun {
                margin-top: 5px;
                margin-left: 10px;
            }
        }
    }

    .conditionInit {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .testOperation {
        position: absolute;
        z-index: 3;
    }

    .testOperation2 {
        position: absolute;
        left: 100px;
        z-index: 3;
    }

    .editFlow {}

    .simulate {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        // background: linear-gradient(90deg, rgb(19, 53, 94), rgb(31, 81, 133));

    }
}