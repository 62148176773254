:root {
  --main-color: #1677ff; // 主题色
  --sec-main-color: #1677ffac; // 次主题色
  --hover-main-color: #e6f4ff;
  --font-size: 14px; // 字体大小
  --title-font-size: 16px; // 标题字体大小
  --sec-font-size: 12px;
  --font-color: #202020; // 字体颜色
  --sec-font-color: #202020a6; // 次字体颜色
  --background-color: #fff; // 背景色
  --sec-background-color: #f0f2f5; // 次背景色
  --padding: 20px; // 内边距大小
  --margin: 20px; // 外边距大小
  --border-color: rgba(229, 232, 239, 1); // 边框颜色
  --box-shadow: 0px 2px 20px 0px rgba(190, 190, 190, 0.4); // 阴影
}

/*
主色：#1677ff
副色：#1677ffac
底色：#202020cc
副底色：#202020
文字：#799888
按钮：#42df88   #4be992   #ceffe5     #fffae9
线条：#f0f3f3
顶部导航文字 #789787   hover：#fffae9
*/

// 公共颜色样式
$main: #1677ff;
$secMain: #1677ffac;
$bgMain: #fff;
$bgSecMain: #fafafa;
$font: #202020;
$active: #202020a6;
$line: #2020204d;
$border: #00000014;
// 按钮
$btnBgMain: #4cee95;
$btnBgSec: #ceffe5;
$btnFont: #003e30;
// 内容
$info: #0a4638;
$list: #fdfdfd;
// icon
$red: #f32525;
$blue: #395bce;
$green: #25a666;
$grey: #888888;
$purple: #b235b2;
$darkgreen: #004737;
$forgreen: forestgreen;
// 对应的浅色icon
$reds: #ffdede;
$blues: #ebeefa;
$greens: #edfcf3;
$greys: #f8f8f8;
$purples: #f6e8f6;
$darkgreens: #e4f4f0;
$forgreens: #e8f6e8;
// 步骤条
$stepBar: mediumaquamarine;
$process: $main;
$wait: #7f9e97;
// 2D
$dark: rgb(67, 67, 67);
$light: white;
