@import "~/assets/style/variables.scss";

.ChatAIContainer {
  position: relative;
  display: flex;
  width: -webkit-fill-available;
  height: 100%;
  overflow: auto;
  border-radius: 4px;
  // margin: var(--padding);
  box-sizing: border-box;
  flex-direction: column;
  .fold {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .foldIcon {
      width: 18px;
      height: 40px;
      background-color: var(--background-color);
      margin: 2px;
      margin-top: 25%;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-color);
    }
    .foldIcon:hover {
      width: 18px;
      color: var(--sec-main-color);

      box-shadow: 0px 2px 6px 0px rgba(53, 51, 51, 0.4);
      // border: 1px solid rgba(229, 232, 239, 1);
    }
  }

  .layoutLeft {
    width: 260px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--background-color);
    box-shadow: var(--box-shadow);
    .title {
      padding: 10px;
      box-sizing: border-box;
      line-height: 22px;
      color: var(--font-color);
      font-size: var(--font-size);
      font-weight: bold;
      text-align: left;
    }
    .chatRecord {
      line-height: 22px;
      font-size: var(--font-size);
      text-align: left;

      .recordItem {
        text-align: left;
        line-height: 22px;
        font-size: var(--font-size);
      }
    }
  }
  .historyField {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 10;
    background: #fff;
    border-top: 1px solid #e9e9e9;
    padding: 16px;
    box-sizing: border-box;
    overflow: auto;
  }
  .layoutCenter {
    flex-grow: 1;
    height: calc(100% - 60px);
    // width: calc(100% - 260px - 315px);
    padding: 0 14px;
    overflow: auto;
    box-sizing: border-box;
    background-color: #e9e9e9;
  }
  .layoutRight {
    width: 315px;
    height: 100%;
    border-radius: 4px;
    background-color: var(--background-color);
    box-shadow: var(--box-shadow);
  }
}
