@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel .chatFlowItem .chatContent .chatText {
  .markdownBox {
    white-space: normal;
    .flowIcon {
      h3 {
        padding: 20px 0;
      }
      $jiare: url("../../../images/symbol/jiare.png");
      $jiangwen: url("../../../images/symbol/jiangwen.png");
      $yiye: url("../../../images/symbol/yiye.png");
      $zhenyao: url("../../../images/symbol/zhenyao.png");
      .symbolFlow {
        overflow: hidden;
        .symbol {
          float: left;
          width: 126px;
          height: 126px;
          margin: 8px;
          background: $jiangwen no-repeat center;
          background-size: contain;
        }
        .yiye {
          background-image: $yiye;
        }
        .jiare {
          background-image: $jiare;
        }
        .zhenyao {
          background-image: $zhenyao;
        }
        .jiangwen {
          background-image: $jiangwen;
        }
      }
      img {
        width: 100%;
      }
    }
    ul,
    ol,
    li {
      list-style: initial;
      padding: revert-layer;
    }

    h1 {
      line-height: 32px;
    }
    h2 {
      line-height: 28px;
    }
    h3 {
      line-height: 26px;
    }
    p {
      line-height: 26px;
    }
  }
}
.ChatAIContainer .chatPanel .chatFlowItem .chatContent .newArticle {
  em {
    background-color: rgba(60, 185, 89, 0.64);
    font-style: normal;
  }
  del {
    text-decoration: initial;
    background-color: rgba(215, 38, 38, 0.53);
  }
}
