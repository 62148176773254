@import "~/assets/style/variables.scss";
.aiActionView {
  padding: 0px 0px;
  box-sizing: border-box;
  overflow: hidden;
  .treeColBox {
    position: relative;
    // height: 46px;
    line-height: 46px;
    padding: 0;
    text-align: left;
    background: #fff;

    ul.paramList {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      list-style: none;
      padding: 0;

      .frontShow {
        display: inline-block;
        text-align: right;
        min-width: 66px;
        padding-right: 10px;
      }

      .textVal {
        color: $main;
      }
    }

    .actionList {
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 8px;
      border-top: 1px solid #ccc;
    }

    .taskInfo {
      line-height: 32px;
      padding: 7px 0;

      span.groupTitle {
        width: calc(100% - 100px);
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .posBtn {
    // position: absolute;
    // bottom: 10px;
    // left: 0px;
  }
}
.robot {
  position: relative;
}
