.avatarBox {
  position: fixed;
  bottom: 10px;
  left: -410px;
  z-index: 1;
  width: 150px;
  height: 280px;
  // border-radius: 8px;
  // background-color: #e28a0e;
  // box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.45);
  transition: all 2s;
  .recordStatus {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 2px 0 rgb(247, 37, 37);
    background-color: rgb(247, 37, 37);
  }
  .avatarHd {
    position: absolute;
    top: -282px;
    width: 100%;
    height: 42px;
    z-index: 1;
    border-bottom: 1px solid #ccc;
  }
  .avatarBd {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    video {
      // background-color: #dee1e6;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      border-radius: 8px;
      border: 1px solid rgb(147 147 147 / 50%);
      box-shadow: 0 1px 8px 0 rgb(147 147 147 / 50%);
      margin: 0 auto;
      overflow: hidden;
      //   border-radius: 40px;
    }
    video.active {
      border: 1px solid #1677ff;
      box-shadow: 0 1px 8px 0 #1677ff;
    }
  }
}
.initAvatar {
  bottom: 10px;
  left: 10px;
  width: 150px;
  height: 280px;
}
