@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel .chatFlowItem .chatContent {
  .warningView {
    font-size: 14px;
    border: 1px solid #fff;
    padding: 5px;
    background-color: #f4d186;
  }
}
