html,
body,
div,
span,
p,
header,
footer,
section,
article,
aside,
nav,
hgroup,
figure,
figcaption,
menu,
figure,
figcaption,
mark,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  // background-color: #fff;

  #root {
    width: 100%;
    height: 100%;
    // overflow: scroll;
  }
}

ul,
ol,
li {
  list-style: none;
  padding-inline-start: 0px;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  border: 0;
}

input,
button,
textarea {
  outline: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.rootSkeleton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 长文本省略
.longTextShowOneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
