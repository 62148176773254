.cfMain {
  width: 100%;
  height: 100%;
  // position: absolute;
  margin: 0;
  padding: 0;
  // z-index: 1;
  // background-color: aqua;
  // background-size: 100vh 100vw;
  // background-color: rgb(255, 208, 0);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  padding: 0px;
  width: 100%;
  // height: 15%;
  top: 0;
  left: 0;

  .selectBox {
    border: 1px solid #55aaff;
    background-color: rgba(75, 160, 255, 0.3);
    position: fixed;
  }

  .labelDiv {
    top: 0;
    width: 100px;
    padding: 10px;
    color: #fff;
    display: block;
    font-size: 14px;
    // position: absolute;
    z-index: -99;
    background-color: rgba(20, 143, 211, 0.68);
    // box-shadow: 0 0 12px rgba(0, 128, 255, 0.75);
    border: 1px solid rgba(127, 177, 255, 0.75);
  }

  .cfRef {
    width: 100%;
    height: 100%;
  }

  .displayPanel {
    width: 200px;
    // height: 400px;
    right: 10px;
    top: 10px;
    color: #000;
    display: block;
    font-size: 14px;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5725490196);

    .panelContent {
      padding: 20px;
      text-align: left;

      .title {
        margin-top: 5px;
        font-size: 16px;
        text-align: left;
        padding-top: 16px;
        padding-bottom: 14px;
      }

      .moduleName {
        margin-top: 5px;
        margin-left: 10px;
      }

      .moduleFun {
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }
}
