@import "~/assets/style/variables.scss";

.ChatAIContainer .aiHeaderField {
  width: 100%;
  height: 60px;
  // margin-top: 20px;
  .title {
    display: inline-block;
    line-height: 60px;
    padding-left: 16px;
  }
  .actionItem {
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;

    .iconfont {
      color: var(--font-color);
      font-size: 22px;
    }
  }
}
