@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel .initalView {
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  .logo {
    // width: 280px;
    height: 100px;
    padding-left: 120px;
    line-height: 100px;
    // 不换行
    white-space: nowrap;
    overflow: hidden;
    font-size: 36px;
    font-weight: bold;
    color: #101010;
    margin-bottom: 8px;
    background: url("../../../images/ai-logo.png") left no-repeat;
    margin-bottom: 20px;
    background-size: 100px 100px;
  }
  .decs {
    text-align: center;
    // font-weight: bold;
    font-size: 25px;
    margin-bottom: 40px;
    color: #101010;
  }
  .chatContent {
    float: left;
    width: calc(100% - 32px);
    padding: 20px;
    // padding-right: 0;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 20px;
    border-radius: 8px;
    // background-color: var(--background-color);
    text-align: left;
    font-size: 14px;
    // box-shadow: 0px 2px 20px 0px rgba(190, 190, 190, 0.4);
    font-family: Roboto;
    // border: 1px solid var(--border-color);
    box-sizing: border-box;

    .tipList {
      font-size: 14px;
      display: block;
      .tipItem {
        padding: 20px;
        border: 1px solid rgba(187, 187, 187, 1);
        background-color: #fff;
        line-height: 42px;
        border-radius: 4px;
        margin-bottom: 16px;
        .iconfont {
          float: left;
          /* width: auto; */
          padding-right: 10px;
          height: 45px;
          color: rgba(215, 38, 38, 0.83);
        }
        .title {
          font-size: 18px;
          color: var(--font-color);
          font-weight: bold;
        }
        .desc {
          font-size: 14px;
          color: var(--sec-font-color);
        }
      }
    }
  }
}
