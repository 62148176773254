@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel .chatFlowItem .chatContent {
  .authorArticleView {
    width: 100%;
    position: relative;
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgb(204 213 255 / 40%);
    .title {
      padding-top: 10px;
      line-height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .journalBox {
      padding: 10px 0;
      box-sizing: border-box;
      cursor: pointer;
      overflow: auto;
      height: 184px;
      transition: all 0.3s;

      .btnL {
        position: absolute;
        left: 2px;
        top: 50%;
        z-index: 1;
        line-height: 70px;
        font-size: 24px;
        cursor: pointer;
        transform: rotate(180deg) translateY(50%);
      }
      .btnR {
        position: absolute;
        right: 2px;
        top: 50%;
        z-index: 1;
        line-height: 70px;
        font-size: 24px;
        cursor: pointer;
        transform: translateY(-50%);
      }

      .journalList {
        display: inline-flex;
        transition: all 0.3s;
      }
      .journalItem {
        position: relative;
        float: left;
        width: 418px;
        height: 152px;
        padding: 10px;
        margin-right: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        line-height: 26px;
        background-color: #fff;

        .itemTitle {
          font-size: 18px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 定义文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          // max-height: 3em; /* 行高和字体大小相乘来计算最大高度 */

          span {
            font-size: 16px;
            font-weight: normal;
          }
        }

        .title::after {
          content: " \2022"; /* Unicode字符代码 for a bullet */
          padding-left: 5px; /* 图标与文本之间的间隔 */
        }
        .btnLink {
          color: var(--main-color);
        }
        .btnLink.underline {
          text-decoration: underline;
        }
      }
    }
  }
}
