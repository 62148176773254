@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel {
  width: 100%;
  height: calc(100% - 180px);
  overflow: hidden;
  position: relative;

  .scrollBottom {
    position: absolute;
    bottom: 47px;
    left: 50%;
    transform: translateX(-50%);
  }
  .chatTextFild {
    height: 100%;
    overflow: auto;
    // 添加动画
    transition: all 3s ease-in;
    white-space: pre-wrap;
  }

  .chatFlow {
    // padding: 20px;
    // box-sizing: border-box;
  }
  .chatFlowItem {
    padding: 10px 0px;
    box-sizing: border-box;
    overflow: hidden;
    // margin-bottom: 20px;
    .avatar {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
      background: #9747ff;
      color: #fff;
      border: 1px solid #ccc;
      overflow: hidden;
      .loadingAvatar {
        width: 100%;
        height: 100%;
        background: #d6e6fd;
        .anticon {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      img {
        object-fit: cover;
        width: 40px;
        height: 40px;
        // border: 1px solid #ccc;
      }
    }
    .chatContent {
      float: left;
      max-width: calc(100% - 50px - 50px);
      min-height: 45px;

      box-sizing: border-box;
      overflow: hidden;
      line-height: 20px;

      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: center;

      text-align: left;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      .link {
        color: var(--main-color);
        text-decoration: revert-layer;
      }
      .chatText {
        width: 100%;
        height: 100%;
        line-height: 22px;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        // color: rgba(65, 80, 88, 1);
        font-size: 16px;
        text-align: left;
        white-space: normal;
        white-space: pre-wrap; /*css-3*/
        white-space: -moz-pre-wrap; /*Mozilla,since1999*/
        white-space: -pre-wrap; /*Opera4-6*/
        white-space: -o-pre-wrap; /*Opera7*/
        word-wrap: break-word; /*InternetExplorer5.5+*/
        border-radius: 8px;
        background-color: var(--background-color);
        box-shadow: 0px 2px 20px 0px rgba(190, 190, 190, 0.4);
        .vditor {
          margin: 16px;
        }
        .reload {
          cursor: pointer;
          margin-left: 10px;
          color: #ff0000;
          font-size: 18px;
        }
        .devicePic {
          float: left;
          width: 52px;
          height: 52px;
          margin-right: 30px;
          display: inline-block;
          border: 1px solid #ccc;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }
      .chatText:last-child {
        margin: 0;
      }

      // 建议回复 框
      .suggestText {
        margin-bottom: 10px;
        button {
          font-size: 16px;
          color: rgba(16, 16, 16, 1);
          background-color: #fff;
        }
        .iconfont {
          color: rgba(16, 16, 16, 1);
          padding-left: 40px;
        }
      }

      .chatTextNobg {
        background: none;
        padding: 0;
        box-shadow: none;
      }
    }
    .chatAction {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      float: left;
      width: 100%;
      // height: 40px;
      margin-top: 10px;
    }
  }
  .robot {
    .avatar {
      background: url("../../images/ai-logo.png") center no-repeat #fff;
      background-size: contain;
    }
  }
  .user {
    .avatar {
      float: right !important;
      margin-left: 10px;
      margin-right: 0px;
      line-height: 40px;
      text-align: center;
      // background-image: none !important;
      border: 1px solid #ccc;
      background: url("../../images/user-avatar.jpeg") center no-repeat;
      background-size: cover;
    }
    .chatContent {
      max-width: calc(100% - 102px);
      float: right;
      line-height: 20px;

      color: #fff;
      font-size: 16px;
      text-align: left;

      .chatText {
        box-shadow: 0px 2px 20px 0px rgba(190, 190, 190, 0.4);
        border: 1px solid rgba(0, 122, 255, 1);
        border-radius: 8px;
        background: linear-gradient(
          91.42deg,
          rgba(0, 122, 255, 1) 1%,
          rgba(84, 64, 248, 0.3) 100%,
          rgba(119, 40, 245, 0) 100%
        );
      }
      .chatFiles {
        // width: 100%;
        min-width: 300px;
        height: 52px;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        padding: 10px;
        box-sizing: border-box;
        background-color: #fff;
        color: var(--font-color);
        cursor: pointer;

        .fileIcon {
          float: left;
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
        .fileName {
          float: left;
          line-height: 32px;
          width: calc(100% - 32px);
          font-size: 14px;
        }
        .filePreview {
          float: right;
          font-weight: bold;
          line-height: 32px;
          font-size: 16px;
        }
      }
    }
    .chatAction {
      float: right !important;
    }
  }
}

.methodSummary {
  white-space: normal;
  white-space: pre-wrap; /*css-3*/
  white-space: -moz-pre-wrap; /*Mozilla,since1999*/
  white-space: -pre-wrap; /*Opera4-6*/
  white-space: -o-pre-wrap; /*Opera7*/
  word-wrap: break-word; /*InternetExplorer5.5+*/

  font-size: 16px;
  p {
    margin-top: 10px;
  }
  margin-bottom: 10px;
}

.ideaText {
  .title {
    font-weight: 800;
  }
}
