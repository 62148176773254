@import "~/assets/style/variables.scss";

.ChatAIContainer .layoutCenter .inputField {
  width: 100%;
  height: 147px;
  margin-top: 20px;
  .btnNew {
    float: left;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    text-align: center;
    line-height: 38px;
    color: #fff;
    background-color: #8f3a83;
    cursor: pointer;
  }
  // padding: 0 40px;
  .inputInner {
    float: left;
    margin-left: 16px;
    width: calc(100% - 60px);
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
  }
  .actionFiled {
    width: 100%;
    height: 50px;
    padding: 10px 14px;

    // border-bottom: 1px solid var(--border-color);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .rightField {
      // float: right;
    }
    .actionItem {
      line-height: 20px;
      background-color: #fff;
      border: 1px solid rgba(16, 16, 16, 0.3);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      border-radius: 32px;
    }
    .active {
      background-color: rgba(0, 122, 255, 0.05);
      color: var(--main-color);
      border-color: rgba(0, 122, 255, 0.05);
    }
    .actionBtn {
      width: 36px;
      height: 36px;
      line-height: 36px;
      // background: linear-gradient(
      //   91.42deg,
      //   #007aff 0%,
      //   #5154bb 55%,
      //   #7728f5 100%
      // );
      cursor: pointer;
      color: var(--font-color);
      font-size: 14px;
      text-align: center;
      border-radius: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    .recording {
      box-shadow: 0px 0px 2px 2px var(--main-color);
    }
    .actionBtn:hover {
      background-color: #e8e8e8;
    }
    .d-line {
      width: 1px;
      height: 36px;
      background-color: #e8e8e8;
    }
    .sendBtn {
    }
    // .sendBtn:hover {
    //   background-color: #ccc;
    // }
  }
  .inputBox {
    width: 100%;
    height: 96px;
    padding: 10px 0 0 0;
    font-size: 14px;
    box-sizing: border-box;

    textarea {
      border: none;
      outline: none;
      height: 90px !important;
      box-shadow: none !important;
    }
  }
}
