@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel .chatFlowItem .chatContent {
  .suggestText {
    background: var(--background-color);
    border: 1px solid var(--main-color);
    box-shadow: none;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
  }
}
