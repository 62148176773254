@import "~/assets/style/variables.scss";
.rootSpin > .ant-spin-container {
  width: 100vw;
  height: 100vh;
  // min-width: 1440px;
}
.resize-horizon {
  position: relative;
}
.rootPanel {
  width: 100% !important;
}
.resize-handle-horizon {
  // box-shadow: inset 0px 0px 2px 2px #bebebe;
  cursor: col-resize !important;
  // border-radius: 10px;
}
.resize-handle-horizon {
  background-color: #1677ff !important;
}
// .resize-handle-horizon:hover {
//   box-shadow: 0px 0px 2px 2px #1677ff;
//   // background-color: #1677ff !important;
//   cursor: col-resize !important;
//   border-radius: 10px;
// }
.aiView {
  width: 230px;
}

// AI自动执行的蒙层动画
@keyframes moveRight {
  from {
    box-shadow: inset 0px 0px 19px 0px #1677ff;
  }
  to {
    box-shadow: inset 0px 0px 6px 0px #1677ff;
  }
}

.autoAICover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.0509803922);
  cursor: pointer;
  box-shadow: inset 0px 0px 19px 0px #1677ff;
  transition: all 2s;
  animation-name: moveRight;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  h3 {
    position: absolute;
    top: 16px;
    left: 50%;
    color: var(--main-color);
    transform: translateX(-50%);
  }
}
.rootSpin {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  background: rgb(182 182 182 / 30%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 828px) {
  .rootSpin {
    width: 100%;
    height: 100%;
  }

  .rootSpin > .ant-spin-container {
    width: 100%;
    height: 100%;
    // min-width: 100%;
  }
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 5px;
}
