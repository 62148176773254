@import "~/assets/style/variables.scss";

.ChatAIContainer .chatPanel .chatFlowItem .chatContent {
  .journalView {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgb(204 213 255 / 40%);
    .title {
      line-height: 32px;
      display: flex;
      justify-content: space-between;
    }
    .btnL {
      position: absolute;
      left: 2px;
      top: 50%;
      z-index: 1;
      font-size: 20px;
      cursor: pointer;
      transform: rotate(180deg) translateY(50%);
    }
    .btnR {
      position: absolute;
      right: 2px;
      top: 50%;
      z-index: 1;
      font-size: 20px;
      cursor: pointer;
      transform: translateY(-50%);
    }
    .journalBox {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      box-sizing: border-box;

      overflow: hidden;
      .ant-space {
        position: relative;
        left: 0;
        transition: all 0.3s;
      }
      .journalItem {
        width: 200px;
        height: 80px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        line-height: 26px;
        background-color: #fff;

        .title {
          font-size: 32px;
          line-height: 37px;
        }
      }
    }
  }
}
